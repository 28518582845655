<template>
	<div class="app-main__inner">
		<div class="container-fluid">
			<div class="row mb-3">
				<div class="col-md-3 col-xl-3">
					<div class="bg-white h-full rounded p-3 bg-theme-colord">
						<div
							class="text-center text-color-themed font-semibold text-base whitespace-nowrap font-medium">
							{{ $t('mostSold') }}
						</div>
						<div class="flex justify-between space-x-3">
							<ul class="text-xs font-medium text-color-themed">
								<li v-for="app in GET_HOUSE_METRICS.most_sold_apps.slice(0, 4)" :key="uid(app)">
									{{ app.app_type }}
								</li>
							</ul>
							<ul class="text-xs font-medium text-color-themed">
								<li v-for="app in GET_HOUSE_METRICS.most_sold_apps.slice(4)" :key="uid(app)">
									{{ app.app_type }}
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-xl-3">
					<div class="bg-white h-full rounded p-3 bg-theme-blue">
						<div
							class="text-center text-color-theme-blue font-semibold text-base whitespace-nowrap font-medium">
							{{ $t('totalSubscription') }}
						</div>
						<div class="text-center mt-1">
							<span class="text-2xl text-color-theme-blue font-semibold" id="total-sub">{{
									$services.helpers.animateValue('total-sub', 0, GET_HOUSE_METRICS.total_subscriptions, 1000)
								}}</span>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-xl-3">
					<div class="bg-white h-full rounded p-3 bg-theme-green">
						<div class="text-center text-color-theme-green font-semibold text-base font-medium">
							{{ $t('subscriptionMonth') }}
						</div>
						<div class="text-center mt-1">
							<span class="text-2xl text-color-theme-green font-semibold" id="subscrip-month">{{
									$services.helpers.animateValue('subscrip-month', 0, GET_HOUSE_METRICS.total_subscriptions_this_month, 4000)
								}}</span>
						</div>
					</div>
				</div>
				<div class="col-md-3 col-xl-3">
					<div class="bg-white h-full rounded p-3 bg-theme-org">
						<div
							class="text-center text-color-theme-org font-semibold text-base whitespace-nowrap font-medium">
							{{ $t('totalPartners') }}
						</div>
						<div class="text-center mt-1">
							<span class="text-2xl text-color-theme-org font-semibold" id="total-partners">{{
									$services.helpers.animateValue('total-partners', 0, GET_HOUSE_METRICS.total_partners, 4000)
								}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-3 mt-3">
				<div class="col-md-12">
					<div class="row " v-if="loadingGraph">
						<div class="col-md-3 col-xl-3 mb-2" v-for="i in 4" :key="i">
							<p class="h-32 w-full bg-gray-200 rounded mb-2"></p>
						</div>
					</div>

					<div id="row-test-graph" class="row" v-else>
						<div class="col-md-6 col-xl-6" v-for="(sub, i) in DashboardSub" :key="i">
							<div class="mb-3 card bg-custom-theme-gray">
								<!--								<div class="card-header-tab card-header bg-custom-theme-gray">-->
								<!--									&lt;!&ndash; <div class="card-header-title font-size-lg text-capitalize font-weight-normal">-->
								<!--									</div>-->
								<!--									<div class="btn-actions-pane-right text-capitalize actions-icon-btn">-->
								<!--										<div class="btn-group dropdown">-->
								<!--											<button type="button" data-toggle="dropdown" aria-haspopup="true"-->
								<!--												aria-expanded="false" class="btn-icon btn-icon-only btn btn-link">-->
								<!--												<i class="pe-7s-menu btn-icon-wrapper"></i>-->
								<!--											</button>-->
								<!--										</div>-->
								<!--									</div> &ndash;&gt;-->
								<!--								</div>-->
								<div class="p-0 card-body">
									<div class="widget-chat-wrapper-outer mb-2">
										<div class="widget-chart-content pt-3 pb-1">
											<!-- <h6 class="widget-subheading mb-0 opacity-5 text-dark font-semibold mb-2 unset-opa-increa-size card-text text-xs">{{ sub.options.title.text }} Transaction History</h6> -->
											<div class="widget-chart-flex">
												<div class="widget-numbers p-2">
													<apexchart :ref="'chart'+i" type="line" height="200"
															   :options="sub.options"
															   :series="sub.options.series"></apexchart>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<ul class="body-tabs body-tabs-layout nav mb-2">
					<li class="nav-item">
						<a role="tab" class="tab-btn nav-link active" id="tab-0" data-toggle="tab" href="#tab-content-0"
						   aria-selected="true">
							<span class="text-xs-custom">{{ $t('firm') }}</span>
						</a>
					</li>
					<li class="nav-item">
						<a role="tab" class="tab-btn nav-link" id="tab-1" data-toggle="tab" href="#tab-content-1"
						   aria-selected="false">
							<span class="text-xs-custom">{{ $t('companies') }}</span>
						</a>
					</li>
				</ul>
				<!-- <span id="search">lef</span>
				<span id="search2">rig</span> -->
			</div>

			<div class="tab-content">
				<div class="tab-pane tabs-animation fade active show" id="tab-content-0" role="tabpanel">
					<div class="main-card mb-3 bg-white">
						<div class="flex justify-end p-2">
							<div class="form-group">
								<input type="text" @input="searchInputTrigger"
									   class="flex border px-3 py-2 bg-white focus:outline-none rounded"
									   v-bind:placeholder="$t('search')">
							</div>
						</div>
						<div class="card-body o-pad">
							<table style="width: 100%;" id="exampl"
								   class="table table-hover table-striped table-bordered">
								<thead>
								<tr>
									<th class="text-xs">{{ $t('firmName') }}</th>
									<th class="text-xs">{{ $t('firmNumber') }}</th>
									<th class="text-xs">Actions</th>
								</tr>
								</thead>
								<tbody v-loading='vLoadingFirm'>
								<tr v-for="(firm, i) in GET_ALL_FIRM.results" :key="i">
									<td class="text-xs font-light">
										<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
											<router-link v-if="firm.primary_accountant" :data-cy="'single-'+i"
														 :to="{ name : 'singlefirm', query : { partnerId : firm.primary_accountant.id, firm : firm.name }, params : { partner : firm.accounting_partner_id, id: firm.primary_accountant.id } }"
														 class="no-deco">
												{{ firm.name | capitalizeNames }}
											</router-link>
											<span v-else>{{ firm.name | capitalizeNames }}</span>
										</el-tooltip>
									</td>
									<td class="text-xs font-light">{{ firm.firm_number || firm.company_id }}</td>
									<td class="flex space-x-4 font-light">
										<el-tooltip :content="$t('enterCompanyInfo')" placement="top"
													v-if="firm.primary_accountant">
											<router-link :data-cy="'single-'+i"
														 :to="{ name : 'singlefirm', query : { partnerId : firm.primary_accountant.id, firm : firm.name }, params : { partner : firm.accounting_partner_id, id: firm.primary_accountant.id } }"
														 class="no-deco">
												<span
													class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
											</router-link>
										</el-tooltip>
										<el-tooltip :content="$t('noteHovering')" placement="top"
													v-if="firm.primary_accountant">
											<span @click="checkCompanyNote(firm.primary_accountant, 'Firm')"
												  class="text-base cursor-pointer iconly-brokenPaper-Plus icon-color"></span>
										</el-tooltip>
										<el-tooltip :content="$t('deleteHovering')" placement="top"
													v-if="firm.primary_accountant">
											<span @click="deleteFirm(firm.primary_accountant)"
												  class="text-base cursor-pointer  iconly-brokenDelete icon-color"></span>
										</el-tooltip>

										<!-- @click="getFirmUserID(firm.primary_accountant.id)" -->
										<!-- <router-link :to="{ name : 'singlefirm', query : { partnerId : firm.primary_accountant.id }, params : { partner : firm.accounting_partner_id, id: firm.primary_accountant.id } }">
											<svg class="h-5 w-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
											<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
											</svg>
										</router-link>
										<span>
											<svg class="h-5 w-5 text-yellow-400 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
											</svg>
										</span>
										<span>
											<svg @click="deleteFirm(firm.primary_accountant.id)" class="h-5 w-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
											</svg>
										</span> -->
									</td>
								</tr>
								</tbody>
							</table>
							<div class="float-right mb-5" v-if="GET_ALL_FIRM.results">
								<paginate
									:page-count="GET_ALL_FIRM.total_page || 1"
									:click-handler="changePageNumber"
									prev-text="<"
									next-text=">"
									:container-class="'pagination'">
								</paginate>
							</div>
						</div>
					</div>
				</div>
				<div class="tab-pane tabs-animation fade" id="tab-content-1" role="tabpanel">
					<div class="main-card bg-white mb-3">
						<div class="flex justify-end p-2">
							<div class="form-group">
								<input type="text" @input="searchInputTriggerCompany"
									   class="flex border px-3 py-2 bg-white focus:outline-none rounded"
									   v-bind:placeholder="$t('search')">
							</div>
						</div>
						<div class="card-body o-pad">
							<table style="width: 100%;" id="exampl"
								   class="table table-hover table-striped table-bordered">
								<thead>
								<tr>
									<th class="text-xs">{{ $t('companyName') }}</th>
									<th class="text-xs">{{ $t('companyNumber') }}</th>
									<th class="text-xs">Actions</th>
								</tr>
								</thead>
								<tbody v-loading='vLoadingCom'>
								<tr v-for="(company, i) in GET_ALL_COMPANY.results" :key="i">
									<td class="text-xs font-light">
										<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
											<span class="cursor-pointer" @click="getCompanyUserID(company.id)">{{
													company.company.name | capitalizeNames
												}}</span>
										</el-tooltip>
									</td>
									<td class="text-xs font-light">{{ company.company.company_id }}</td>
									<td class="flex space-x-4 font-light">
										<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
											<span @click="getCompanyUserID(company.id)"
												  class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
										</el-tooltip>
										<el-tooltip :content="$t('viewAndEditCompanyInformation')" placement="top">
											<span @click="editCompanyInformation(company)"
												  class="text-base cursor-pointer iconly-brokenShow icon-color"></span>
										</el-tooltip>
										<el-tooltip :content="$t('noteHovering')" placement="top">
											<span @click="checkCompanyNote(company.id, 'Company')"
												  class="text-base cursor-pointer iconly-brokenPaper-Plus icon-color"></span>
										</el-tooltip>
										<el-tooltip :content="$t('deleteHovering')" placement="top">
											<span @click="deleteUserCompany(company.id)"
												  class="text-base cursor-pointer  iconly-brokenDelete icon-color"></span>
										</el-tooltip>

										<!-- <span>
											<svg @click="getCompanyUserID(company.id)" class="h-5 w-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
											<path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
											</svg>
										</span>
										<span>
											<svg @click="openNote(company.id)" class="h-5 w-5 text-yellow-400 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
											</svg>
										</span>
										<span>
											<svg @click="deleteUserCompany(company.id)" class="h-5 w-5 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
											</svg>
										</span> -->
									</td>
								</tr>
								</tbody>
							</table>
							<div class="float-right mb-5" v-if="GET_ALL_COMPANY.results">
								<paginate
									:page-count="GET_ALL_COMPANY.total_page"
									:click-handler="changeCompanyPageNumber"
									prev-text="<"
									next-text=">"
									:container-class="'pagination'">
								</paginate>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="board_actions">
			<span class="subtle" :class="{'hidden':!unsavedChanges}">There are unsaved changes</span>
			<button v-on:click="saveBoards" :disabled="!unsavedChanges">Save</button>
			<button class="menu-toggle invert" @click.stop="toggleSidebar">☰</button>
			</div> -->
			<!-- <saved-boards :boards="boards" :active-board-index="activeBoardIndex"></saved-boards> -->
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import Paginate from 'vuejs-paginate'
import debounce from 'lodash/debounce'
import bus from './../../bus.js'
import {Tooltip} from 'element-ui'

Vue.use(Tooltip)

// import Vue from 'vue'
// Vue.component(
//     'apexchart',
//     // A dynamic import returns a Promise.
//     () => import('vue-apexcharts')
// )
import {mapGetters} from 'vuex'

export default {
	name: 'dashboard-single',
	components: {
		paginate: Paginate,
		'apexchart': () => import('vue-apexcharts')
	},
	data() {
		return {
			DashboardSub: [],
			houseMetrics: {
				most_sold_apps: [],
				total_partners: 0,
				total_subscriptions: 0,
				total_subscriptions_this_month: 0,
			},
			loadingGraph: true,
			loadingSub: true,
			totalSubscription: 0,
			mostSold: 0,
			soldApps: [],
			totalPartners: 0,
			totalSaving: 0,
			tabId: 1,
			allFirms: [],
			allCompanies: [],
			companyID: null,
			activeBoardIndex: 0,
			unsavedChanges: false,
			boards: [],
			updateUser: false,
			createNote: false,
			userIdNote: null,
			noteId: null,
			userAgent: null,
			vLoadingFirm: true,
			vLoadingCom: true,
			chartAreaOptions: {
				chart: {
					toolbar: {
						show: false
					},
					height: 350,
					type: 'bar',
				},
				stroke: {
					width: [1, 1],
					curve: 'straight',
				},
				fill: {
					type: 'solid'
				},
				title: {
					text: 'Traffic Sources'
				},
				colors: ['#f7347a', '#66bb99', '#47578f', '#293c42'],
				dataLabels: {
					enabled: false,
				},
				legend: {
					show: true,
					showForSingleSeries: true,
					showForNullSeries: true,
					showForZeroSeries: true,
				},
				labels: ['Jan', 'Feb', 'Mar', 'Apr', this.$t('may'), 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				xaxis: {
					// type: 'datetime'
				},
				yaxis: [
					{
						title: {
							text: '',
						},
						// tickAmount: 0,
						labels: {
							formatter: function (val) {
								return val.toFixed(0)
							},
						},
					}
				],
			}
		}
	},
	computed: {
		// activeBoard () {
		//     return this.boards[this.activeBoardIndex] || false
		// },
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_ALL_FIRM: 'house/GET_ALL_FIRM',
			GET_HOUSE_METRICS: 'house/GET_HOUSE_METRICS',
			GET_ALL_COMPANY: 'house/GET_ALL_COMPANY',
		})
	},
	created() {
		// this.loadState()
		// if (this.boards.length === 0) {
		//     this.boards.push(this.createBoard(true))
		// }
	},
	watch: {
		// boards: {
		//     handler: function (newVal, oldVal) {
		//             // check if we're loading the app for the first time
		//         if (oldVal[0] && !oldVal[0].initial) {
		//             this.unsavedChanges = true
		//         }
		//     },
		//     deep: true // watch EVERYTHING
		// }
	},
	mounted() {
		if (this.GET_USER_COMPANY_ID) {
			// this.getDashboardSubscription(this.GET_USER_COMPANY_ID)
			// this.getUserTransaction(this.GET_USER_COMPANY_ID)
		} else {
			// this.getUserTransaction()
			// this.getDashboardSubscription()
		}
		this.getHouseMetrics()
		this.getHouseGraphMetrics()
		// this.getInHouseSubscription()
		this.getAllFirms()
		this.getAllCompanies()
	},
	methods: {
		openCommentModal() {
			return this.$store.commit('utility/OPEN_INVITE_MODAL')
		},
		openNote(id) {
			if (this.boards[0].notes.length < 1) {
				this.companyID = id
				this.$refs.board.addNote('neutral')
			}
		},
		getHouseMetrics() {
			this.$store.dispatch('house/getHouseMetrics')
				.then(_ => {
				})
				.catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 403) {
							bus.$emit('redirect-user-dashboard')
						}
					}
				})
		},
		getHouseGraphMetrics() {
			this.$store.dispatch('house/getHouseGraphMetrics').then(resp => {
				//console.log(resp.data.data)
				let graph_title = {
					subscriptions_joined_by_month: 'Subscription created / month',
					subscriptions_cancelled_by_month: 'Subscription cancelled / month',
					companies_joined_by_month: 'Company created / month',
					partners_joined_by_month: 'Firm created / month',
				}
				for (const [key, value] of Object.entries(resp.data.data)) {
					let payload = {
						title: graph_title[key]
					}
					payload.options = JSON.parse(JSON.stringify(this.chartAreaOptions))
					payload.options.series = []
					payload.options.title.text = graph_title[key]
					//console.log(Object.keys(value))
					//console.log(Object.values(value))
					let data = {
						data: Object.values(value),
						name: graph_title[key]
					}
					payload.options.series.push(data)
					this.DashboardSub.push(payload)
				}
				this.loadingGraph = false
				//console.log(this.DashboardSub)
			}).catch(_ => {
				this.loadingGraph = false
			})
		},
		changePageNumber(num) {
			this.vLoadingFirm = true
			const payload = {
				page: num
			}
			this.$store.dispatch('house/getAllFirms', payload)
				.then(res => {
					//console.log(res)
					this.vLoadingFirm = false
				}).catch(err => {
				this.vLoadingFirm = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAllFirms()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.$store.dispatch('house/searchAccountingFirm', {value: event.target.value})
					.then(_ => {
					})
					.catch(_ => {
					})
			} else {
				this.$store.commit('house/FILTER_DEFAULT_FIRM', {})
			}
		}, 500),
		searchInputTriggerCompany: debounce(function (event) {
			if (event.target.value !== '') {
				if (parseInt(event.target.value)) {
					this.$store.dispatch('house/searchCompany', {value: event.target.value, num: true})
						.then(_ => {
						})
						.catch(_ => {
						})
				} else {
					this.$store.dispatch('house/searchCompany', {value: event.target.value, num: false})
						.then(_ => {
						})
						.catch(_ => {
						})
				}
			} else {
				this.$store.commit('house/FILTER_DEFAULT_COMPANY', {})
			}
		}, 500),
		changeCompanyPageNumber(num) {
			this.vLoadingCom = true
			const payload = {
				page: num
			}
			this.$store.dispatch('house/getAllCompanies', payload)
				.then(_ => {
					this.vLoadingCom = false
				}).catch(err => {
				this.vLoadingCom = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAllCompanies()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getAllFirms() {
			this.vLoadingFirm = true
			this.$store.dispatch('house/getAllFirms', {})
				.then(res => {
					//console.log(res)
					this.vLoadingFirm = false
				}).catch(err => {
				console.log(err)
				this.vLoadingFirm = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAllFirms()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getAllCompanies() {
			this.vLoadingCom = true
			this.$store.dispatch('house/getAllCompanies', {})
				.then(_ => {
					this.vLoadingCom = false
				}).catch(err => {
				this.vLoadingCom = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAllCompanies()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		// createCompanyNote () {
		//     if (this.boards[0].notes.length > 0 && this.boards[0].notes[0].text !== '') {
		//         this.$services.helpers.notification(this.$t('createNote'), 'progress', this)
		//         const noteText = this.boards[0].notes
		//         this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
		//         this.$services.axios.post(this.$services.endpoints.MANAGERS_NOTE + `${this.companyID}/`, {
		//             recipient: this.companyID,
		//             message: noteText[0].text,
		//             read: false
		//         })
		//         .then(res => {
		//             console.log(res)
		//             if (res.data.success) {
		//                 this.$services.helpers.notification(this.userAgent + ' ' + this.$t('nCreated'), 'success', this)
		//                 bus.$emit('remove-note', this.boards[0].notes[0].id)
		//             }
		//         }).catch((_) => {

		//         })
		//     } else {
		//         this.$services.helpers.notification(this.$t('emptyNote'), 'error', this)
		//     }
		// },
		// updateUserNote () {
		//     if (this.boards[0].notes.length > 0 && this.boards[0].notes[0].text !== '') {
		//         this.$services.helpers.notification(this.$t('upNote'), 'progress', this)
		//         const noteText = this.boards[0].notes
		//         this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
		//         this.$services.axios.put(this.$services.endpoints.MANAGERS_NOTE + `update/${this.noteId}/${this.userIdNote}/`, {
		//             recipient: this.userIdNote,
		//             message: noteText[0].text,
		//         })
		//         .then(res => {
		//             console.log(res)
		//             if (res.data.success) {
		//                 this.$services.helpers.notification(this.userAgent + ' ' + this.$t('nUpdated'), 'success', this)
		//                 bus.$emit('remove-note', this.boards[0].notes[0].id)
		//             }
		//         }).catch((err) => {
		//             console.log(err.response.data)
		//         })
		//     } else {
		//         this.$services.helpers.notification(this.$t('emptyNote'), 'error', this)
		//     }
		// },
		checkCompanyNote(userId, agent) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.$services.endpoints.COMPANY_NOTES + `?user_id=${userId}`
			this.$store.dispatch('accountant/checkCompanyNote', URL)
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.data.success) {
						if (res.data.data.length > 0) {
							bus.$emit('open-sticky', {
								userId,
								updateUser: true,
								agent,
								noteID: res.data.data[0].id,
								message: res.data.data[0].message
							})
						} else {
							bus.$emit('open-sticky', {
								userId,
								updateUser: false,
								agent,
								message: ''
							})
						}
					}
				}).catch((_) => {

			})
		},
		getFirmUserID(userID) {
			this.$store.commit('customer/SET_USER_FIRM_ID', userID)
			setTimeout(() => {
				window.open('/accountant', '_blank')
			}, 1000)
		},
		getCompanyUserID(userID) {
			this.$store.commit('customer/SET_USER_COMPANY_ID', userID)
			setTimeout(() => {
				window.open('/automatisera', '_blank')
			}, 1000)
		},
		editCompanyInformation(user) {
			window.Bus.$emit('open-company-edit-profile', user)
		},
		uid(e) {
			if (e.uid) return e.uid
			const key = Math.random()
				.toString(16)
				.slice(2)
			this.$set(e, 'uid', key)
			return e.uid
		},
		deleteUserCompany(d) {
			// console.log(d)
		},
		deleteFirm(id) {
			// console.log(id)
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.bg-theme-colord {
	background: #FEF1F5 !important;
	border: 0.5px solid $theme-primary-color;
}

.text-color-themed {
	color: $theme-primary-color;
}

.bg-theme-blue {
	background: #F2F0FF !important;
	border: 0.5px solid $theme-secondary-color;
}

.text-color-theme-blue {
	color: $theme-secondary-color;
}

.bg-theme-green {
	background: #F2FDF5 !important;
	border: 0.5px solid #1ED050;
}

.text-color-theme-green {
	color: #1ED050;
}

.bg-theme-org {
	background: #FEF6F1 !important;
	border: 0.5px solid #F28A4F;
}

.text-color-theme-org {
	color: #F28A4F;
}

.tab-btn.active {
	box-shadow: unset !important;
	border: 1px solid $theme-secondary-color !important;
	font-weight: 800;
	color: $theme-secondary-color !important;
	border-radius: 5px;
}

.tab-btn {
	color: $theme-secondary-light;
}


.bg-custom-theme-blue {
	background: #8AACDE !important;
}

.bg-custom-theme-teal {
	background: #85CED2 !important;
}

.bg-custom-theme-green {
	background: #BBE5AC !important;
}

.bg-custom-theme-gray {
	background-color: #F4F6EC !important;
}

.bg-custom-light-brown {
	background: #E5CFAC !important;
}

.bg-custom-theme-pink {
	background: #E19CD2 !important;
}

.unset-opa-increa-size {
	opacity: unset !important;
	font-size: 1.3rem;
}

.card-text-num {
	color: #7E3469 !important;
	font-weight: 800;
	float: right;
}
</style>
<style src="./../../assets/common.scss" lang="scss"></style>
